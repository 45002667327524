import { Box, Drawer, Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import GeneralErrorPage from "components/ErrorComponents/GeneralErrorPage";
import { LynxButton } from "components/LynxComponents/LynxButton/LynxButton";
import LynxTypography from "components/LynxComponents/LynxTypography/LynxTypography";
import { FullScreenLoadingIndicator } from "components/ReusableComponents/LoadingIndicator/FullScreenLoadingIndicator";
import { LynxAvatar } from "components/ReusableComponents/LynxAvatar/LynxAvatar";
import ThorPagination from "components/ReusableComponents/ThorPagination/ThorPagination";
import { ModuleChip } from "components/userManagement/ModuleChip/ModuleChip";
import { displayPopupNotification } from "helpers/displayPopupNotification";
import { observer } from "mobx-react";
import { NotificationType } from "models/shared/NotificationType";
import { actions } from "models/userManagement/actions";
import { MarvelPermissionStatus, UserListItem, UserStatus } from "models/userManagement/userManagementModels";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useStore } from "store/StoreConfigs";
import UserActions, { UserActionOption } from "../UserActions";
import AddUserForm from "../forms/AddUserForm";
import { EditUserForm } from "../forms/EditUserForm";
import { userManagementStyles } from "./userManagementStyles";
import dayjs from "dayjs";
import { PaginationArea } from "models/shared/Page";

export default observer(function UsersTab() {
    const classes = userManagementStyles();
    const { userStore, identityStore, permissionsStore, commonStore } = useStore();
    const navigate = useNavigate();
    const currentTimezone = dayjs.tz.guess();

    const [showAddUserForm, setShowAddUserForm] = useState(false);
    const [showEditUserForm, setShowEditUserForm] = useState(false);

    const tableHeaders = ["Name", "Email", "User Groups", "Status"];

    const addUserSuccessCallback = (userId: string) => {
        setShowAddUserForm(false);
        const successNotification = `User '${userId}' was created successfully`;
        displayPopupNotification(NotificationType.SUCCESS, successNotification);
        userStore.clearPagination();
        userStore.loadUsers();
    };

    const editUserSuccessCallback = (userId: string) => {
        setShowEditUserForm(false);

        const successNotification = `User '${userId}' was edited successfully`;
        displayPopupNotification(NotificationType.SUCCESS, successNotification);
    };

    const editPermission = identityStore.isSystemSpace
        ? permissionsStore.getPermissionResult(actions.system.users.manage)
        : permissionsStore.getPermissionResult(actions.customer.users.manage, identityStore.currentCustomer.id);

    const viewPermission = identityStore.isSystemSpace
        ? permissionsStore.getPermissionResult(actions.system.users.view)
        : permissionsStore.getPermissionResult(actions.customer.users.view, identityStore.currentCustomer.id);

    useEffect(() => {
        userStore.loadUsers();

        return () => commonStore.setShowGeneralErrorPageToFalse();
    }, [userStore.currentPage, userStore.pageSize]);

    const getUserActions = (user: UserListItem) => {
        const result: UserActionOption[] = [
            {
                optionName: "View / Edit Details",
                action: () => {
                    userStore.loadUserDetails(user.id);
                    setShowEditUserForm(true);
                },
            },
        ];

        if (
            editPermission.status === MarvelPermissionStatus.Allow &&
            (identityStore.isSystemSpace || !user.isSystemUser) &&
            user.id !== identityStore.currentUser.id
        ) {
            const userStatusOption =
                user.status === UserStatus.Deactivated ? UserStatus.Active : UserStatus.Deactivated;
            result.push({
                optionName: userStatusOption,
                action: () => userStore.changeUserStatus(user.id, userStatusOption),
            });
        }

        if (viewPermission.status === MarvelPermissionStatus.Allow) {
            result.push({
                optionName: "Audit Trail",
                action: () => navigate(`/userManagement/users/${user.id}/audit`),
                withImmediateAction: true,
            });
        }

        return result;
    };

    return (
        <main className={classes.tabContainer}>
            <LynxButton
                size="medium"
                className={classes.addButton}
                onClick={() => setShowAddUserForm(true)}
                disabled={editPermission.status !== MarvelPermissionStatus.Allow}
                loading={editPermission.status === MarvelPermissionStatus.Loading}
            >
                Add New User
            </LynxButton>

            <LynxButton
                className={classes.exportButton}
                variant="secondary"
                size="medium"
                onClick={() => {
                    identityStore.isSystemSpace
                        ? userStore.getSystemUsersListCsv({ currentTimezone })
                        : userStore.getCustomerUsersListCsv({
                              customerId: identityStore.currentCustomer.id,
                              currentTimezone,
                          });
                }}
                disabled={viewPermission.status !== MarvelPermissionStatus.Allow}
                loading={
                    userStore.progressFlags.loadUserslistCsv || viewPermission.status === MarvelPermissionStatus.Loading
                }
            >
                Export to CSV
            </LynxButton>
            {showAddUserForm && (
                <Drawer
                    anchor="right"
                    open={showAddUserForm}
                    onClose={() => setShowAddUserForm(false)}
                    className={classes.drawer}
                >
                    <AddUserForm successCallback={addUserSuccessCallback} />
                </Drawer>
            )}

            {showEditUserForm && (
                <Drawer
                    anchor="right"
                    open={showEditUserForm}
                    onClose={() => setShowEditUserForm(false)}
                    className={classes.drawer}
                >
                    <EditUserForm successCallback={editUserSuccessCallback} />
                </Drawer>
            )}

            {userStore.progressFlags.loadingUsers ? (
                <FullScreenLoadingIndicator />
            ) : commonStore.showGeneralErrorPage ? (
                <GeneralErrorPage />
            ) : (
                <>
                    <Table className={classes.table}>
                        <TableHead>
                            {tableHeaders.map((header) => (
                                <TableCell className={classes.headerCell} key={header}>
                                    {header}
                                </TableCell>
                            ))}
                            <TableCell className={classes.headerCell} />
                        </TableHead>
                        <TableBody>
                            {userStore.users.map((user) => (
                                <React.Fragment key={user.id}>
                                    <TableRow className={classes.tableRow} key={user.id}>
                                        <TableCell
                                            style={{
                                                display: "table-cell",
                                                verticalAlign: "middle",
                                                borderSpacing: "initial",
                                            }}
                                        >
                                            <Box display="flex" alignItems="center" gridGap="0.5rem">
                                                <LynxAvatar firstName={user.firstName} lastName={user.lastName} />
                                                <LynxTypography className={classes.userName}>
                                                    {user.firstName} {user.lastName}
                                                </LynxTypography>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <LynxTypography className={classes.tableText}>{user.id}</LynxTypography>
                                        </TableCell>
                                        <TableCell>
                                            {user.groups.map((group) => (
                                                <ModuleChip
                                                    key={group.id}
                                                    text={group.name}
                                                    moduleId={group.moduleId}
                                                />
                                            ))}
                                        </TableCell>
                                        <TableCell>
                                            <LynxTypography className={classes.tableText}>{user.status}</LynxTypography>
                                        </TableCell>
                                        <TableCell>
                                            <UserActions options={getUserActions(user)} />
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>

                    <ThorPagination
                        page={userStore.currentPage}
                        pages={userStore.totalPages}
                        onPageChange={userStore.movePage}
                        isLastPage={userStore.isLastPage}
                        setPage={userStore.setPage}
                        area={PaginationArea.Users}
                        setPageSize={userStore.setPageSize}
                    />
                </>
            )}
        </main>
    );
});
