import { AxiosResponse, InternalAxiosRequestConfig } from "axios";

export const apiRoutes = {
    events: {
        listEvents: (customerId: string) => `customers/${customerId}/events`,
        getEventShortInfo: (customerId: string, eventId: string) =>
            `customers/${customerId}/events/${eventId}/shortInfo`,
        getEventDetails: (customerId: string, eventId: string) => `customers/${customerId}/events/${eventId}`,
        getEventDeviceData: (customerId: string, eventId: string) =>
            `customers/${customerId}/events/${eventId}/deviceData`,
        makeProductDecision: (customerId: string, eventId: string, assessmentId: string) =>
            `customers/${customerId}/events/${eventId}/assessments/${assessmentId}/makeProductDecision`,
        getProductFlows: (customerId: string, eventId: string, productId: string) =>
            `customers/${customerId}/events/${eventId}/products/${productId}/flows`,
        getBatchHistory: (customerId: string, eventId: string, batchId: string) =>
            `customers/${customerId}/events/${eventId}/batches/${batchId}/batchHistory`,
        getRsbSummaryForBatch: (customerId: string, eventId: string, batchId: string) =>
            `customers/${customerId}/events/${eventId}/batches/${batchId}/rsbSummary`,
        setFlowForAssessment: (customerId: string, eventId: string, assessmentId: string, flowId: string) =>
            `customers/${customerId}/events/${eventId}/assessments/${assessmentId}/changeFlow/${flowId}`,
        changeDeliveryTemperatureRange: (customerId: string, eventId: string) =>
            `customers/${customerId}/events/${eventId}/changeDeliveryTransportationRange`,
        setEventPriority: (customerId: string, eventId: string) =>
            `customers/${customerId}/events/${eventId}/setPriority`,
        setEventCodes: (customerId: string, eventId: string) => `customers/${customerId}/events/${eventId}/setCodes`,
        assignUserToEvent: (customerId: string, eventId: string) =>
            `customers/${customerId}/events/${eventId}/assignUser`,
        completeEventReview: (customerId: string, eventId: string) =>
            `customers/${customerId}/events/${eventId}/completeEventReview`,
        confirmFileUpload: (customerId: string, eventId: string, attachmentId: string) =>
            `customers/${customerId}/events/${eventId}/attachments/${attachmentId}/confirmUpload`,
        getDownloadPresignedUrl: (customerId: string, eventId: string, attachmentId: string) =>
            `customers/${customerId}/events/${eventId}/attachments/${attachmentId}/presignedDownloadUrl`,
        getDownloadPresignedS3Url: (customerId: string, eventId: string) =>
            `customers/${customerId}/events/${eventId}/presignedDownloadUrl`,
        deleteEventAttachment: (customerId: string, eventId: string, attachmentId: string) =>
            `customers/${customerId}/events/${eventId}/attachments/${attachmentId}`,
        getEventAttachments: (customerId: string, eventId: string) =>
            `customers/${customerId}/events/${eventId}/attachments`,
        setEventType: (customerId: string, eventId: string) => `customers/${customerId}/events/${eventId}/setType`,
        setEventTimezone: (customerId: string, eventId: string) =>
            `customers/${customerId}/events/${eventId}/setTimezone`,
        setDueDate: (customerId: string, eventId: string) => `customers/${customerId}/events/${eventId}/setDueDate`,
        setQuarantineDate: (customerId: string, eventId: string) =>
            `customers/${customerId}/events/${eventId}/setQuarantineDate`,
        resetAllEvents: (customerId: string) => `customers/${customerId}/resetEvents`,
        resetEvent: (customerId: string, eventId: string) => `customers/${customerId}/events/${eventId}/reset`,
        setShippingDate: (customerId: string, eventId: string) =>
            `customers/${customerId}/events/${eventId}/setShippingDate`,
        setDeliveryDate: (customerId: string, eventId: string) =>
            `customers/${customerId}/events/${eventId}/setDeliveryDate`,
        setEventProcessType: (customerId: string, eventId: string) =>
            `customers/${customerId}/events/${eventId}/setProcessType`,
        conditionEvent: (customerId: string, eventId: string) => `customers/${customerId}/events/${eventId}/condition`,
        getEventComments: (customerId: string, eventId: string) => `customers/${customerId}/events/${eventId}/comments`,
        createEventComment: (customerId: string, eventId: string) =>
            `customers/${customerId}/events/${eventId}/comments/create`,
        calculateDevicesExcursion: (customerId: string) => `customers/${customerId}/events/calculateDevicesExcursion`,
        calculateManualExcursion: (customerId: string) => `customers/${customerId}/events/calculateManualExcursion`,
        createManualEvent: (customerId: string) => `customers/${customerId}/events/create`,
        getModelForEventEditing: (customerId: string, eventId: string) =>
            `customers/${customerId}/events/${eventId}/modelForEventEditing`,
        editEvent: (customerId: string, eventId: string) => `customers/${customerId}/events/${eventId}/edit`,
        generatePdf: (customerId: string, eventId: string) => `customers/${customerId}/events/${eventId}/pdf`,
        getDuplicateDeviceEvents: (customerId: string) => `customers/${customerId}/events/getDuplicateDevices`,
        uploadFileAndSaveToDb: (customerId: string, eventId: string) =>
            `customers/${customerId}/events/${eventId}/uploadFile`,
        uploadFile: (customerId: string) => `customers/${customerId}/events/attachments/uploadFile`,
    },
    stabilityForms: {
        getFormDownloadPresignedUrl: (customerId: string, formId: string) =>
            `customers/${customerId}/forms/${formId}/presignedDownloadUrl`,
        listStabilityForms: (customerId: string) => `customers/${customerId}/stabilityForms`,
        createStabilityForm: (customerId: string) => `customers/${customerId}/stabilityForms/create`,
        getStabilityFormDetails: (customerId: string, stabilityFormId: string) =>
            `customers/${customerId}/stabilityForms/${stabilityFormId}`,
        getStabilityFormForEditing: (customerId: string, stabilityFormId: string) =>
            `customers/${customerId}/stabilityForms/${stabilityFormId}/edit`,
        editStabilityForm: (customerId: string, stabilityFormId: string) =>
            `customers/${customerId}/stabilityForms/${stabilityFormId}`,
        getStabilityFormChangesHistory: (customerId: string, stabilityFormId: string) =>
            `customers/${customerId}/stabilityForms/${stabilityFormId}/changesHistory`,
        generatePdf: (customerId: string, stabilityFormId: string) =>
            `customers/${customerId}/stabilityForms/${stabilityFormId}/pdf`,
    },
    customers: {
        listCustomers: `customers`,
        getCustomerDetails: (customerId: string) => `customers/${customerId}`,
        createCustomer: `customers`,
        editCustomer: (customerId: string) => `customers/${customerId}`,
        listModules: `modules`,
        listCustomerModules: (customerId: string) => `customers/${customerId}/modules`,
        getCustomerRegions: (customerId: string) => `customers/${customerId}/regions`,
        getCustomerEventPriorities: (customerId: string) => `customers/${customerId}/eventPriorities`,
        getCustomerEventCodes: (customerId: string) => `customers/${customerId}/eventCodes`,
        getCustomerDeliveries: (customerId: string) => `customers/${customerId}/deliveries`,
        getCustomerTransportationModes: (customerId: string) => `customers/${customerId}/transportationModes`,
        getCustomerLocations: (customerId: string) => `customers/${customerId}/locations`,
        getCustomerBatches: (customerId: string) => `customers/${customerId}/batches`,
        getCustomerDevices: (customerId: string) => `customers/${customerId}/devices`,
        getCustomerProductSupportInfo: (customerId: string) => `customers/${customerId}/productSupportInfo`,
        getCustomerProductUnitOfMeasure: (customerId: string) => `customers/${customerId}/productUnitOfMeasure`,
        getCustomerProducts: (customerId: string) => `customers/${customerId}/products`,
    },
    permissions: {
        evaluatePermissions: `permissions/evaluate`,
        listSystemPermissions: `system/permissions`,
        listCustomerPermissions: (customerId: string) => `customers/${customerId}/permissions`,
        listSystemRoles: `system/roles`,
        listCustomerRoles: (customerId: string) => `customers/${customerId}/roles`,
    },
    users: {
        forgotPassword: "public/user/forgotPassword",
        resetPassword: "public/user/resetPassword",
        verifyRecoveryToken: "public/user/verifyRecoveryToken",
        getCurrentUserDetails: `users/self`,
        listSystemUsers: `system/users`,
        getSystemUserDetails: (userId: string) => `system/users/${userId}`,
        createSystemUser: `system/users`,
        editSystemUser: (userId: string) => `system/users/${userId}`,
        listCustomerUsers: (customerId: string) => `customers/${customerId}/users`,
        getCustomerUserDetails: (customerId: string, userId: string) => `customers/${customerId}/users/${userId}`,
        createCustomerUser: (customerId: string) => `customers/${customerId}/users`,
        editCustomerUser: (customerId: string, userId: string) => `customers/${customerId}/users/${userId}`,
        approveAgreementTerms: () => `users/agreementApprove`,
        getCustomerUsersListCsv: (customerId: string) => `customers/${customerId}/users/csv`,
        getSystemUsersListCsv: `system/users/csv`,
        getUserGuidePdf: (userGuideName: string) => `userGuide/${userGuideName}`,
    },
    groups: {
        listSystemGroups: `system/groups`,
        getSystemGroupDetails: (groupId: string) => `system/groups/${groupId}`,
        createSystemGroup: `system/groups`,
        editSystemGroup: (groupId: string) => `system/groups/${groupId}`,
        listCustomerGroups: (customerId: string) => `customers/${customerId}/groups`,
        getCustomerGroupDetails: (customerId: string, groupId: string) => `customers/${customerId}/groups/${groupId}`,
        createCustomerGroup: (customerId: string) => `customers/${customerId}/groups`,
        editCustomerGroup: (customerId: string, groupId: string) => `customers/${customerId}/groups/${groupId}`,
    },
    audit: {
        logAuditEntry: () => `signin/audit`,
        getAuditEntries: (customerId: string) => `customers/${customerId}/audit`,
        getHumanReadableCustomerAuditEntries: (customerId: string) => `customers/${customerId}/audit/readable`,
        getHumanReadableSystemAuditEntries: "system/audit/readable",
        getCustomerAuditEntityShortInfo: (customerId: string) => `customers/${customerId}/audit/shortInfo`,
        getSystemAuditEntityShortInfo: "system/audit/shortInfo",
        exportCustomerAuditEntriesToPdf: (customerId: string) => `customers/${customerId}/audit/pdf`,
        exportSystemAuditEntriesToPdf: "system/audit/pdf",
        exportCustomerAuditEntriesToCsv: (customerId: string) => `customers/${customerId}/audit/csv`,
        exportSystemAuditEntriesToCsv: "system/audit/csv",
        auditUserSignInSuccess: "audit/signin/success",
        auditUserSignInFailure: "public/audit/signin/failure",
    },
    token: {
        getCsrfToken: () => `token/csrf`,
    },
};

export const bypassErrorInterceptor = (
    response: AxiosResponse<any> | undefined,
    config: InternalAxiosRequestConfig
) => {
    const [url, method] = [config.url, config.method];

    const conditions = [
        (url: string, method: string) => url === "/users" && method === "post",
        (url: string, method: string) => url === "/customers" && method === "post",
        (url: string, method: string) => url.includes("/customers") && method === "patch",
        (url: string, method: string) => url === "/groups" && method === "post",
        (url: string, method: string) => url.includes("/eventBatchStatus") && method === "patch",
    ];

    return conditions.some((x) => x(url!, method!));
};
